import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardActionArea, CardMedia, CardContent, Typography } from '@mui/material';

interface IndustryCardProps {
    image: string;
    title: string;
    id: string; // Unique identifier for each industry
}

const IndustryCard: React.FC<IndustryCardProps> = ({ image, title, id }) => {
    // Check if the title is not 'Metals'
    const isComingSoon = title !== 'Metals' && title !== 'Electronic Components';

    return (
        <CardActionArea component={isComingSoon ? 'div' : Link} to={isComingSoon ? '' : `/industry/${id}/dashboard`}>
            <div className="w-full rounded-3xl mb-2.5 relative bg-white">
                {/* Gray overlay for coming soon */}
                {isComingSoon && (
                    <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-80 rounded-3xl z-10 flex items-center justify-center">
                        <Typography variant="h3" component="div" className="text-red-500 z-20 font-orbitron">
                            Coming Soon
                        </Typography>
                    </div>
                )}

                <div className="flex items-center">
                    {/* Left side containing the image */}
                    <div className="flex-1 max-w-[150px] max-h-[200px] overflow-hidden rounded-3xl">
                        <CardMedia
                            component="img"
                            height="100%"
                            image={image}
                            alt={title}
                            className="object-cover"
                        />
                    </div>
                    {/* Right side containing the card content */}
                    <div className="flex-1 flex flex-col z-20">
                        <CardContent className="max-h-[200px] overflow-hidden">
                            <Typography gutterBottom variant="h5" component="div" className="font-orbitron">
                                {title}
                            </Typography>
                        </CardContent>
                    </div>
                </div>
            </div>
        </CardActionArea>

    );
}

export default IndustryCard;
