import React, {useEffect, useState} from "react";
import ReactApexChart from "react-apexcharts";
import {ApexOptions} from "apexcharts";
import {useParams} from "react-router-dom";
import industryData from "../DataContent/industryData";


const Dashboard: React.FC = () => {

    const [dashboardTable, setDashboardTable] = useState<any[]>([]);

    const {id} = useParams<{ id: string }>();

    useEffect(() => {
        // Find the industry data matching the specific id
        const industry = industryData.find((item: any) => item.id === id);

        // Check if the industry is found and has a dashboardTable
        if (industry && industry.dashboardTable) {
            // Set the dashboardTable data to state
            setDashboardTable(industry.dashboardTable);
        }
    }, [id]);


    // Sample made-up data
    const data = {
        time_series_data: [
            {date_created: '2024-01-01', automated_quotes: 50, manual_quotes: 30, pdv: 8000},
            {date_created: '2024-01-02', automated_quotes: 70, manual_quotes: 20, pdv: 7500},
            {date_created: '2024-01-03', automated_quotes: 80, manual_quotes: 60, pdv: 10000},
            {date_created: '2024-01-04', automated_quotes: 60, manual_quotes: 40, pdv: 9000},
            {date_created: '2024-01-05', automated_quotes: 90, manual_quotes: 50, pdv: 12000},
            {date_created: '2024-01-06', automated_quotes: 100, manual_quotes: 70, pdv: 13000},
            {date_created: '2024-01-07', automated_quotes: 120, manual_quotes: 80, pdv: 14000},
            {date_created: '2024-01-08', automated_quotes: 110, manual_quotes: 90, pdv: 11500},
            {date_created: '2024-01-09', automated_quotes: 130, manual_quotes: 100, pdv: 15000},
            {date_created: '2024-01-10', automated_quotes: 140, manual_quotes: 120, pdv: 17000},
        ],
    };

    // Chart options configuration
    const chartOptions: ApexOptions = {
        chart: {
            type: "area",
            toolbar: {
                show: true,
                tools: {
                    download: false,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
                autoSelected: "zoom",
            },
        },
        colors: ["#F98A09", "#356F9F"],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "smooth",
            width: 2,
        },
        xaxis: {
            type: "datetime",
            categories: data?.time_series_data.map((item) => item.date_created),
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            show: true,
            labels: {
                show: true,
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        grid: {
            show: false,
        },
        legend: {
            show: false, // This hides the legend
        },
        tooltip: {
            shared: true,
            intersect: false,
            x: {
                format: "dd/MM/yy",
            },
            y: {
                formatter: function (value, {seriesIndex, dataPointIndex, w}) {
                    return value?.toLocaleString();
                },
            },
            custom: function ({series, seriesIndex, dataPointIndex, w}) {
                const manualQuotes = series[1][dataPointIndex];
                const automatedQuotes = series[0][dataPointIndex];
                const pdv = data.time_series_data[dataPointIndex]?.pdv;

                return `
                    <div className="apexcharts-tooltip-box">
                        <div style="padding: 5px;">
                            <div style="display: flex; justify-content: space-between; margin-bottom: 5px;">
                                <span style="color: #356F9F;">Manual Quotes:</span>
                                <span style="font-weight: bold;">${manualQuotes?.toLocaleString()}</span>
                            </div>
                            <div style="display: flex; justify-content: space-between; margin-bottom: 5px;">
                                <span style="color: #F98A09;">Automated Quotes:</span>
                                <span style="font-weight: bold;">${automatedQuotes?.toLocaleString()}</span>
                            </div>
                            <div style="display: flex; justify-content: space-between; border-top: 1px solid #e0e0e0; padding-top: 5px;">
                                <span>Pipeline dollar value (PDV):</span>
                                <span style="font-weight: bold;"> $${pdv?.toLocaleString()}</span>
                            </div>
                        </div>
                    </div>
                `;
            },
        },
        fill: {
            opacity: 0.3,
        },
    };

    // Chart series data
    const chartSeries = [
        {
            name: "Automated Quotes",
            data: data?.time_series_data.map((item) => item.automated_quotes),
        },
        {
            name: "Manual Quotes",
            data: data?.time_series_data.map((item) => item.manual_quotes),
        },
    ];


    return (
        <div className={`flex flex-col lg:flex-row gap-2 pt-2 max-w-full `}>
            <div className={`flex flex-col gap-2 w-full lg:w-3/4`}>
                <div className={`flex flex-col sm:flex-row gap-2`}>
                    <div className={`w-full sm:w-2/3`}>
                        <div
                            className={`bg-[#205B89] text-white shadow-sm py-4 px-8 flex gap-2 justify-between rounded-md items-center relative min-h-32 h-full`}>
                            <div className={`pr-20`}>
                                <div>
                                    <p className="text-xl font-francois">Good afternoon</p>
                                </div>
                                <div>
                                    <p className="font-biryani text-sm pt-3">Your positive attitude and willingness to
                                        take on challenges make a significant impact on our team.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`w-full sm:w-1/3`}>
                        <div
                            className={`bg-white shadow-sm py-4 px-8 flex gap-2 justify-between rounded-md items-center relative min-h-32 h-full`}>
                            <div className={`grid grid-cols-1 gap-2 font-francois text-gray-600`}>
                                <p>Won Quotes</p>
                                <div className="flex gap-2 font-biryani mt-2 items-center">
                                    <p className="font-biryani text-gray-600 font-medium text-2xl">2</p>
                                    <p className="font-biryani text-sm text-green-500">▲ 100.00%</p>
                                </div>
                                <p className="font-biryani text-sm text-gray-600">Compared to 1 last month</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col sm:flex-row gap-2">
                    <div className="w-full sm:w-2/3">
                        <div className="bg-white shadow-sm py-4 px-8 rounded-md w-full h-full">
                            <div className="grid grid-cols-2 gap-4 font-biryani text-gray-600">
                                <p className="font-francois">Open Quotes</p>
                                <div className="text-right flex items-start justify-end">
                                    <p className="font-francois">Pipeline dollar value (PDV)</p>
                                </div>
                            </div>
                            <div
                                className="grid grid-cols-2 gap-4 font-biryani mt-2 text-gray-600 font-medium text-2xl">
                                <div>
                                    <div className="flex gap-2 items-end">
                                        <p className="font-biryani">54</p>
                                        <p className="font-francois text-sm text-red-500">▼ -69.14%</p>
                                    </div>
                                    <p className="font-biryani text-sm text-gray-600">Compared to 175 last month</p>
                                </div>
                                <p className="font-biryani text-right">$30,088.1</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full sm:w-1/3">
                        <div className="bg-white shadow-sm py-4 px-8 mb-4 rounded-md h-full w-full">
                            <div className="grid grid-cols-1 gap-2 font-francois text-gray-600">
                                <p>Lost Quotes</p>
                            </div>
                            <div className="flex gap-2 font-biryani mt-2 items-center">
                                <p className="font-biryani text-gray-600 font-medium text-2xl">0</p>
                                <p className="font-biryani text-sm text-red-500">▼ 0.00%</p>
                            </div>
                            <p className="font-biryani text-sm text-gray-600">Compared to 0 last month</p>
                        </div>
                    </div>
                </div>
                <div className={`w-full bg-white p-4`}>
                    <h3 className="font-francois text-lg text-gray-600">Quotes Overview</h3>
                    <p className="p-0 m-0 font-biryani text-[12px]">Total 69 quotes for this month</p>
                    <div className="h-[300px] sm:h-[400px] lg:h-[45vh]">
                        <ReactApexChart options={chartOptions} series={chartSeries} type="area" height="100%"
                                        width="100%"/>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-2 w-full lg:w-1/4">
                <div className="bg-white shadow-sm py-4 px-8 rounded-md h-full">
                    <div className="grid grid-cols-1 gap-2 font-biryani text-gray-600">
                        <p className="font-francois text-lg font-semibold">Top Quote of the day</p>
                        <p className="text-sm font-semibold">RFQ ID: INTR9689000088191</p>
                    </div>
                    <div className="flex flex-col gap-2 mt-2 font-biryani">
                        <p className="text-sm">
                            <span className="font-semibold">Customer:</span>
                            Santiago Campo</p>
                        <p className="text-sm">
                            <span className="font-semibold">
                                Email:
                            </span>
                            santiago.c@wise-sales.com
                        </p>
                        <p className="text-sm">
                            <span className="font-semibold">Company:</span> Wise-Sales</p>
                    </div>
                    <div className="mt-4 font-biryani">
                        <p className="text-sm font-semibold">Shipping Address:</p>
                        <p className="text-sm">288 Snow Drive, Birmingham, AL 35209</p>
                    </div>
                    <div className="grid grid-cols-1 gap-2 font-biryani mt-2 text-sm">
                        <p className="text-gray-600">Total: $855.13</p>
                    </div>
                </div>
                <div className="bg-white shadow-sm py-2 px-4 rounded-md h-full flex flex-col">
                    <p className="font-francois text-lg text-gray-600 mb-4 font-semibold">
                        {id === '0' ? 'Materials Quoted' : 'Components Quoted'}
                    </p>
                    <div className="overflow-y-auto flex-grow max-h-[40vh]">
                        <table className="w-full text-left table-fixed">
                            <thead className="sticky top-0 bg-white">
                            <tr>
                                <th className="w-1/2 px-2 py-2 text-gray-600 text-xs font-semibold">
                                    {id === '0' ? 'Material' : 'Component'}
                                </th>
                                <th className="w-1/4 px-2 py-2 text-gray-600 text-xs font-semibold text-right">Stock
                                    code
                                </th>
                                <th className="w-1/4 px-2 py-2 text-gray-600 text-xs font-semibold text-right">Price</th>
                            </tr>
                            </thead>
                            <tbody className="break-all">
                            {dashboardTable.map((row, index) => (
                                <tr key={index}>
                                    {/* Combined Circle and Material Name */}
                                    <td className="flex items-center px-2 py-2">
                                        <div className="w-8 h-8 flex items-center justify-center rounded-full bg-blue-200 text-blue-700 text-sm font-bold mr-2">
                                            {row.material.charAt(0).toUpperCase()} {/* Initial letter */}
                                        </div>
                                        <span className="text-sm">{row.material}</span>
                                    </td>
                                    <td className="px-2 py-2 text-sm text-right">{row.stockCode}</td>
                                    <td className="px-2 py-2 text-sm text-right">{row.price}</td>
                                </tr>
                            ))}
                            </tbody>


                        </table>
                    </div>
                </div>
            </div>


        </div>


    );

}

export default Dashboard;