import React, {useState} from 'react';
import {TablePagination, Typography} from '@mui/material';
import erpSearchData from '../DataContent/ErpSearchData';  // Adjust the path according to your folder structure
import {useParams} from 'react-router-dom';

const ERPSearch = () => {
    const [formValues, setFormValues] = useState({
        material: '',
        thickness: '',
        width: '',
        length: '',
        color: '',
        shape: '',
        specification: '',
        minimum_qty: '',
        stock: '',
        warehouse: 'All'
    });
    const warehouseOptions = ['Miami', 'Atlanta', 'New York', 'Austin', 'All'];

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [selectedRow, setSelectedRow] = useState(null);
    const [hasResults, setHasResults] = useState(false);
    const {id} = useParams<{ id: string }>();
    const selectedIndustry = id ? parseInt(id, 10) : 0; // Default to 0 if id is undefined
    const [filteredItems, setFilteredItems] = useState<any[]>([]);


    const handleSearch = () => {
        // Check if all filter fields are empty
        const isAllFiltersEmpty = Object.values(formValues).every(value => value === '');

        if (isAllFiltersEmpty) {
            setFilteredItems([]); // Set to empty array if all filters are empty
            setHasResults(false); // Set hasResults to false
            return; // Exit the search function
        }

        // Filter the data based on the selected industry and form values
        const newFilteredItems = erpSearchData
            .filter(item => {
                // Check if the industry matches
                if (selectedIndustry === 0) {
                    return item.industry === "Metals"; // Show metals
                } else if (selectedIndustry === 1) {
                    return item.industry === "Electronic Components"; // Show electronic components
                }
                return false; // No matches
            })
            .flatMap(item =>
                item.items.filter(nestedItem => {
                    // Check if the material is included in the nested item description
                    const matchesMaterial = !formValues.material || nestedItem.description.toLowerCase().includes(formValues.material.toLowerCase());

                    return (
                        matchesMaterial && // Material match from the nested item description
                        (!formValues.thickness || nestedItem.dimensions.thickness.toString() === formValues.thickness) &&
                        (!formValues.width || nestedItem.dimensions.width.toString() === formValues.width) &&
                        (!formValues.length || nestedItem.dimensions.length.toString() === formValues.length) &&
                        // Check if the description contains the color, shape, or specification if they are provided
                        (!formValues.color || nestedItem.description.toLowerCase().includes(formValues.color.toLowerCase())) &&
                        (!formValues.shape || nestedItem.description.toLowerCase().includes(formValues.shape.toLowerCase())) &&
                        (!formValues.specification || nestedItem.description.toLowerCase().includes(formValues.specification.toLowerCase())) &&
                        (!formValues.minimum_qty || nestedItem.quantity >= parseInt(formValues.minimum_qty, 10)) &&
                        (!formValues.stock || nestedItem.stockCode.toLowerCase() === formValues.stock.toLowerCase()) &&
                        (formValues.warehouse === 'All' || nestedItem.warehouse.toLowerCase() === formValues.warehouse.toLowerCase())
                    );
                })
            );

        setFilteredItems(newFilteredItems);
        setHasResults(newFilteredItems.length > 0); // Set hasResults based on the filtered items
    };






    const handleChange = (e: { target: { name: any; value: any; }; }) => {
        setFormValues({
            ...formValues,
            [e.target.name]: e.target.value
        });
    };

    const handleDropdownChange = (e: { target: { value: any; }; }) => {
        setFormValues({
            ...formValues,
            warehouse: e.target.value
        });
    };

    const handleClear = () => {
        setFormValues({
            material: '',
            thickness: '',
            width: '',
            length: '',
            color: '',
            shape: '',
            specification: '',
            minimum_qty: '',
            stock: '',
            warehouse: ''
        });
    };


    const handleRowClick = (index: any) => {
        setSelectedRow(index);
    };

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: { target: { value: string; }; }) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    return (
        <div className={`px-2 py-3 w-full bg-white rounded-md flex flex-col h-auto`}>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-2 mb-4">
                {/* Input Fields */}
                <div className="flex flex-col gap-0 mt-2">
                    <label htmlFor="material" className="text-sm">Material</label>
                    <input
                        type="text"
                        id="material"
                        name="material"
                        value={formValues.material}
                        className="bg-white border rounded-md p-2"
                        onChange={handleChange}
                    />
                </div>

                <div className="flex flex-col gap-0 mt-2">
                    <label htmlFor="thickness" className="text-sm">Thickness</label>
                    <input
                        type="text"
                        id="thickness"
                        name="thickness"
                        value={formValues.thickness}
                        className="bg-white border rounded-md p-2"
                        onChange={handleChange}
                    />
                </div>

                <div className="flex flex-col gap-0 mt-2">
                    <label htmlFor="width" className="text-sm">Width</label>
                    <input
                        type="text"
                        id="width"
                        name="width"
                        value={formValues.width}
                        className="bg-white border rounded-md p-2"
                        onChange={handleChange}
                    />
                </div>

                <div className="flex flex-col gap-0 mt-2">
                    <label htmlFor="length" className="text-sm">Length</label>
                    <input
                        type="text"
                        id="length"
                        name="length"
                        value={formValues.length}
                        className="bg-white border rounded-md p-2"
                        onChange={handleChange}
                    />
                </div>

                <div className="flex flex-col gap-0 mt-2">
                    <label htmlFor="color" className="text-sm">Color</label>
                    <input
                        type="text"
                        id="color"
                        name="color"
                        value={formValues.color}
                        className="bg-white border rounded-md p-2"
                        onChange={handleChange}
                    />
                </div>

                <div className="flex flex-col gap-0 mt-2">
                    <label htmlFor="shape" className="text-sm">Shape</label>
                    <input
                        type="text"
                        id="shape"
                        name="shape"
                        value={formValues.shape}
                        className="bg-white border rounded-md p-2"
                        onChange={handleChange}
                    />
                </div>

                <div className="flex flex-col gap-0 mt-2">
                    <label htmlFor="specification" className="text-sm">Specification</label>
                    <input
                        type="text"
                        id="specification"
                        name="specification"
                        value={formValues.specification}
                        className="bg-white border rounded-md p-2"
                        onChange={handleChange}
                    />
                </div>

                <div className="flex flex-col gap-0 mt-2">
                    <label htmlFor="minimum_qty" className="text-sm">Minimum Quantity</label>
                    <input
                        type="text"
                        id="minimum_qty"
                        name="minimum_qty"
                        value={formValues.minimum_qty}
                        className="bg-white border rounded-md p-2"
                        onChange={handleChange}
                    />
                </div>

                <div className="flex flex-col gap-0 mt-2">
                    <label htmlFor="stock" className="text-sm">Stock</label>
                    <input
                        type="text"
                        id="stock"
                        name="stock"
                        value={formValues.stock}
                        className="bg-white border rounded-md p-2"
                        onChange={handleChange}
                    />
                </div>

                <div className="flex flex-col gap-0 mt-2">
                    <label htmlFor="warehouse" className="text-sm">Warehouse</label>
                    <select
                        id="warehouse"
                        name="warehouse"
                        value={formValues.warehouse}
                        className="bg-white border rounded-md p-2"
                        onChange={handleChange} // Assuming handleChange is modified to handle dropdown changes
                    >
                        <option value="All">All</option>
                        <option value="Miami">Miami</option>
                        <option value="Atlanta">Atlanta</option>
                        <option value="New York">New York</option>
                        <option value="Austin">Austin</option>
                    </select>
                </div>


                <div className="flex justify-end gap-2 mt-2 md:col-span-2">
                    <button
                        className="border border-gray-300 rounded px-4 py-2"
                        onClick={handleClear}
                    >
                        Clear All
                    </button>
                    <button
                        className="bg-[#205B89] text-white rounded px-4 py-2"
                        onClick={handleSearch}

                    >
                        Search
                    </button>
                </div>
            </div>

            {/* Results Section */}
            <div className="mt-2">
                {hasResults ? (
                    <div className="flex-grow h-[40vh] overflow-auto mt-1">
                        <table className="w-full text-left text-gray-500 border">
                            <thead className="sticky top-0 text-xs text-gray-500 bg-gray-300">
                            <tr>
                                <th className="border border-gray-300 p-2">Stock Code</th>
                                <th className="border border-gray-300 p-2">Description</th>
                                <th className="border border-gray-300 p-2">ERP Unit</th>
                                <th className="border border-gray-300 p-2">Unit Cost</th>
                                <th className="border border-gray-300 p-2">Warehouse</th>
                                <th className="border border-gray-300 p-2">Quantity</th>
                                <th className="border border-gray-300 p-2">Dimensions (WxTxL)</th>
                                {/* Updated column header */}
                            </tr>
                            </thead>
                            <tbody>
                            {filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                <tr
                                    key={row.stockCode}
                                    onClick={() => handleRowClick(index)}
                                    className={`cursor-pointer ${selectedRow === index ? 'bg-blue-100' : ''}`}
                                >
                                    <td className="border border-gray-300 p-2">{row.stockCode}</td>
                                    <td className="border border-gray-300 p-2">{row.description}</td>
                                    <td className="border border-gray-300 p-2">{row.ERPUnit}</td>
                                    <td className="border border-gray-300 p-2">{row.unitCost}</td>
                                    <td className="border border-gray-300 p-2">{row.warehouse}</td>
                                    <td className="border border-gray-300 p-2">{row.quantity}</td>
                                    <td className="border border-gray-300 p-2">
                                        {`${row?.dimensions.width} x ${row.dimensions.thickness} x ${row.dimensions.length}`} {/* Combined dimensions */}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={filteredItems.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                ) : (
                    <div className={`px-2 flex items-center justify-center h-60 max-h-60`}>
                        <p className="text-gray-500 font-francois text-[12px]">
                            No results found. Please try different search criteria.
                            <ul className="list-disc pl-4">
                                <li>Dimensions Input: When entering dimensions (length, width, height, etc.), the system
                                    should expect input in inches using decimals, such as "0.2", "0.3", "1", "48", etc.
                                </li>
                                <li>Thickness/Diameter Search: The system interprets the input as a range. For instance:
                                    <ul className="list-disc pl-4">
                                        <li>"0.2" would search for items with a thickness or diameter between 0.200 and
                                            0.299 inches.
                                        </li>
                                        <li>"0.5" would search for items between 0.500 and 0.599 inches.</li>
                                    </ul>
                                </li>
                                <li>Multiple Specifications: If searching for multiple dimensions or specifications,
                                    separate them by commas. For example:
                                    <ul className="list-disc pl-4">
                                        <li>Inputting "0.2, 0.5, 1.0" will search for products that match any of those
                                            thickness ranges (0.200-0.299, 0.500-0.599, 1.000-1.099).
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </p>
                    </div>

                )}
            </div>
        </div>
    );
};

export default ERPSearch;
