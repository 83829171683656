const industryData = [
    {
        image: '/images/metals.jpg',
        title: 'Metals',
        id: '0',
        dashboardTable: [
            {
                material: "Steel",
                stockCode: "S12345",
                price: "$1000"
            },
            {
                material: "Aluminum",
                stockCode: "A98765",
                price: "$800"
            },
            {
                material: "Copper",
                stockCode: "C54321",
                price: "$1200"
            },
            {
                material: "Brass",
                stockCode: "B67890",
                price: "$950"
            },
            {
                material: "Titanium",
                stockCode: "T11223",
                price: "$3000"
            },
            {
                material: "Nickel",
                stockCode: "N99887",
                price: "$1100"
            },
            {
                material: "Zinc",
                stockCode: "Z55443",
                price: "$600"
            },
            {
                material: "Lead",
                stockCode: "L77665",
                price: "$700"
            }
        ],

        emails: [
            {
                rfq: 'RFQ0193124564545125',
                industry: 'Metals',
                id: '0',
                sender: 'Santiago C',
                senderEmail: 'santiago.c@wise-sales.com',
                recipients: ['Ryan Alu'],
                cc: ['Alice Johnson'],
                subject: 'Quote Needed for Metal Supplies',
                content: `Hi Ryan<br>
                I hope this email finds you well. I am reaching out on behalf of Kaiser Aluminum to request a quote for various metal supplies. We are keen to establish a reliable supplier relationship and have heard excellent reviews about Wise-Sales' services.<br>
                    <br>
                    Materials Required:<br>
                        <br>
                            Aluminum Sheets<br>
                            Grade: 6061-T6<br>
                            Thickness: 4mm<br>
                            Quantity: 500 sheets<br>
                            <br>
                                Stainless Steel Bars<br>
                                Type: 304<br>
                                Diameter: 25mm<br>
                                Length: 3 meters<br>
                                Quantity: 200 bars<br>
                                <br>
                                    Copper Coils<br>
                                    Grade: C11000<br>
                                    Thickness: 0.5mm<br>
                                    Quantity: 300 coils<br>
                                    <br>
                    Brass Rods<br>
                    Type: 360<br>
                    Diameter: 20mm<br>
                    Length: 2 meters<br>
                    Quantity: 150 rods<br>
                    <br>
                        We aim to finalize our supplier selection by [specific date, e.g., "the end of this month"]. Your prompt response would greatly assist us in this decision-making process.<br>
                    <br>
                 If you have any questions or require further clarification on our requirements, please do not hesitate to contact me at +1 (420)-225-1215 or via email.<br>
                <br>
                Thank you for your attention to this matter. We look forward to receiving your competitive quote.<br>
                                <br>
                Santiago Campo,<br>
                Warm regards,<br>
                2375 Sierra Ct<br>
                Palo Alto, CA, 94303`
                                    ,
                destination: '2375 Sierra Ct Palo Alto, CA, 94303',
                date: '2024-03-15',
                company: 'Kaiser Aluminum',
                shipments: [
                    {
                        best_quote: {
                            CarrierName: "XPO Logistics",
                            CustomerPrice: {
                                NetPrice: 176.28,
                                FreightCharge: 134.31,
                                GrossCharge: 901.81,
                                Markup: 2.92,
                                margin: 35,
                                margin_on_freight_cost: 271.2,
                                lead_time: "1 - 2 days"
                            },
                        },

                        all_quotes: [
                            {
                                CarrierName: "XPO Logistics",
                                CustomerPrice: {
                                    NetPrice: 176.28,
                                    FreightCharge: 134.31,
                                    margin: 35,
                                    margin_on_freight_cost: 271.2,
                                    lead_time: "1 - 2 days"
                                },
                            },
                            {
                                CarrierName: "XPO Logistics Freight, Inc. - Guaranteed",
                                CustomerPrice: {
                                    NetPrice: 255.03,
                                    FreightCharge: 133.11,
                                    margin: 35,
                                    margin_on_freight_cost: 392.35,
                                    lead_time: "1 - 2 days"
                                },
                            },
                        ],

                        payload: {
                            Shipper: {
                                AddressInfo: {
                                    Address1: "3714 Bluestein Dr. Suite 790",
                                    Address2: "",
                                    City: "Austin",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "TX",
                                    Zipcode: "78721",
                                    LocationName: "Interstate Advanced Materials Austin"
                                },
                                CompanyName: "Interstate Advanced Materials Austin",
                                LocationName: "Interstate Advanced Materials Austin",

                            },
                            Consignee: {
                                ContactInfo: {
                                    FirstName: "Ryan",
                                    LastName: "Kimichick",
                                    ContactName: "Ryan Kimichick",
                                    Email: "santiago.c@interstateAM.com"
                                },
                                AddressInfo: {
                                    Address1: "18096 Idalyn Drive",
                                    Address2: null,
                                    City: "Los Gatos",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "CA",
                                    Zipcode: "95033",
                                    LocationName: "Wise Sales"
                                },
                            },
                            Products: [
                                {
                                    ProductCode: "POLCE  SH00118x048000x0096000",
                                    warehouse: "D1",
                                    Description: "ALU6061T60004",
                                    Length: "96.000",
                                    Width: "48.000",
                                    Height: "0.118",
                                    Quantity: "10",
                                    UnitOfMeasure: "EA",
                                }
                            ],

                        },
                    },

                    {
                        best_quote: {
                            CarrierName: "Estes Express Lines (LTL Standard Transit)",
                            CustomerPrice: {
                                NetPrice: 163.04,
                                FreightCharge: 118.64,
                                margin: 35,
                                margin_on_freight_cost: 250.83,
                                lead_time: "2 - 4 days"
                            },
                        },
                        all_quotes: [
                            {
                                CarrierName: "Estes Express Lines (LTL Standard Transit)",
                                CustomerPrice: {
                                    NetPrice: 163.04,
                                    FreightCharge: 118.64,
                                    margin: 35,
                                    margin_on_freight_cost: 250.83,
                                    lead_time: "2 - 4 days"
                                },
                            },
                            {
                                CarrierName: "Estes Express Lines (Guaranteed LTL Standard Transit: 5PM)",
                                CustomerPrice: {
                                    NetPrice: 213.04,
                                    FreightCharge: 168.97,
                                    margin: 35,
                                    margin_on_freight_cost: 327.75,
                                    lead_time: "2 - 4 days"
                                },
                            },
                        ],

                        payload: {
                            Shipper: {
                                AddressInfo: {
                                    Address1: "3562 South TK Ave.",
                                    Address2: "",
                                    City: "Boise",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "ID",
                                    Zipcode: "83705",
                                    LocationName: "Interstate Advanced Materials Boise"
                                },
                                CompanyName: "Interstate Advanced Materials Boise",
                            },
                            Consignee: {
                                AddressInfo: {
                                    Address1: "18096 Idalyn Drive",
                                    Address2: null,
                                    City: "Los Gatos",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "CA",
                                    Zipcode: "95033",
                                    LocationName: "Wise Sales"
                                },
                                CompanyName: "Wise Sales",
                            },
                            Products: [
                                {
                                    ProductCode: "HDPBE  SH00187x020000x0096000",
                                    warehouse: "C1",
                                    Description: "STASTEEL3040025x3",
                                    Length: "96.000",
                                    Width: "20.000",
                                    Height: "0.187",
                                    Quantity: "20",
                                    UnitOfMeasure: "EA",
                                    PackageType: 29,
                                    Class: 3,
                                }
                            ],

                        },
                    },

                ],

                tableData: [
                    {
                        cutting: [
                            {
                                statusCutting: false,
                                overallyield: '0',
                                totalCuttingCost: '0',
                            }
                        ],
                        stockStatus: 'InStock',
                        id:'123',
                        data: [
                            {label: 'Material Type', value: 'Aluminum'},
                            {label: 'Grade or Alloy', value: '6061-T6'},
                            {label: 'Shape/Form', value: 'Sheet'},
                            {label: 'Diameter', value: 'N/A'}, // Not applicable for sheets
                            {label: 'Thickness', value: '4mm'},
                            {label: 'Length', value: 'N/A'}, // Not applicable for sheets
                            {label: 'Width', value: 'N/A'}, // Not applicable for sheets
                            {label: 'Color/Finish', value: 'Natural'},
                            {label: 'Quantity', value: '500 sheets'},
                            {label: 'Unit of Measure', value: 'SQFT'},
                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: 'Austin'},
                            {label: 'Dimension', value: '0.004'},
                            {label: 'Quantity', value: '500'},
                            {label: 'Quantity Unit', value: 'SQFT'},
                            {label: 'ERP Code', value: 'ALU6061T60004'},
                            {label: 'Material Cost', value: '80'},
                            {label: 'Margin', value: '25'},
                            {label: 'Price', value: ' 200'},
                        ]
                    },
                    {
                        stockStatus: 'PartialStock',
                        id:'13648',
                        data: [
                            {label: 'Material Type', value: 'Stainless Steel'},
                            {label: 'Grade or Alloy', value: '304'},
                            {label: 'Shape/Form', value: 'Bar'},
                            {label: 'Diameter', value: '25mm'},
                            {label: 'Thickness', value: 'N/A'}, // Not applicable for bars
                            {label: 'Length', value: '3 meters'},
                            {label: 'Width', value: 'N/A'}, // Not applicable for bars
                            {label: 'Color/Finish', value: 'N/A'}, // Usually silver or grey
                            {label: 'Quantity', value: '200 bars'},
                            {label: 'Unit of Measure', value: 'pcs'}, // Pieces

                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: 'Austin'},
                            {label: 'Dimension', value: '0.025x3'},
                            {label: 'Quantity', value: '100'},
                            {label: 'Quantity Unit', value: 'pcs'},
                            {label: 'ERP Code', value: 'STASTEEL3040025x3'},
                            {label: 'Material Cost', value: '90'},
                            {label: 'Margin', value: '22'},
                            {label: 'Price', value: '220'},
                        ]
                    },

                    {
                        stockStatus: 'OutOfStock',
                        id:'469741',
                        data: [

                            {label: 'Material Type', value: 'Brass'},
                            {label: 'Grade or Alloy', value: '360'},
                            {label: 'Shape/Form', value: 'Rod'},
                            {label: 'Diameter', value: '20mm'},
                            {label: 'Thickness', value: 'N/A'}, // Not applicable for rods
                            {label: 'Length', value: '2 meters'},
                            {label: 'Width', value: 'N/A'}, // Not applicable for rods
                            {label: 'Color/Finish', value: 'N/A'}, // Usually golden or yellow
                            {label: 'Quantity', value: '150 rods'},
                            {label: 'Unit of Measure', value: 'pcs'}, // Pieces
                            // Add more rows as needed

                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: ''},
                            {label: 'Dimension', value: '0.02x2'},
                            {label: 'Quantity', value: '0'},
                            {label: 'Quantity Unit', value: 'pcs'},
                            {label: 'ERP Code', value: 'BRASS360002x2'},
                            {label: 'Material Cost', value: '85'},
                            {label: 'Margin', value: '24'},
                            {label: 'Price', value: '210'},
                        ]
                    },
                    {
                        stockStatus: 'InStockAlternativeLocations',
                        id:'5745841',
                        data: [
                            {label: 'Material Type', value: 'Copper'},
                            {label: 'Grade or Alloy', value: 'C11000'},
                            {label: 'Shape/Form', value: 'Coil'},
                            {label: 'Diameter', value: 'N/A'}, // Not applicable for coils
                            {label: 'Thickness', value: '0.5mm'},
                            {label: 'Length', value: 'N/A'}, // Not applicable for coils
                            {label: 'Width', value: 'N/A'}, // Not applicable for coils
                            {label: 'Color/Finish', value: 'N/A'}, // Usually reddish or brown
                            {label: 'Quantity', value: '300 coils'},
                            {label: 'Unit of Measure', value: 'pcs'}, // Pieces
                            // Add more rows as needed
                        ],

                        decisionsTable: [
                            {label: 'Sister Location', value: 'Miami'},
                            {label: 'Dimension', value: '0.5'},
                            {label: 'Quantity', value: '500'},
                            {label: 'Quantity Unit', value: 'pcs'}, // Pieces
                            {label: 'ERP Code', value: 'COPC1100005'}, // Pieces
                            {label: 'Material Cost', value: '100'},
                            {label: 'Margin', value: '20'},
                            {label: 'Price', value: '250'},

                        ]
                    },
                ],
                customerResponse: `
                       Hello Santiago,<br><br>
                        Thank you for your ongoing support.<br><br>
                        Please be assured that I am making arrangements to secure the materials that are currently unavailable:<br>
                            - Stainless Steel (Grade 304, Bar, 25mm diameter, 3 meters length)<br>
                            - Brass (Grade 360, Rod, 20mm diameter, 2 meters length)<br><br>
                        Here's the quote you requested.<br><br>
                        <div id='disable-edit'>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Material</th>
                                            <th style='border: 1px solid black;'>Dimensions</th>
                                            <th style='border: 1px solid black;'>Quantity</th>
                                            <th style='border: 1px solid black;'>Color</th>
                                            <th style='border: 1px solid black;'>Availability</th>
                                            <th style='border: 1px solid black;'>Unit Price</th>
                                            <th style='border: 1px solid black;'>Total Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>Aluminum 6061-T6 Sheet</td>
                                            <td style='border: 1px solid black;'>4mm</td>
                                            <td style='border: 1px solid black;'>500 sheets</td>
                                            <td style='border: 1px solid black;'>Natural</td>
                                            <td style='border: 1px solid black;'>In-Stock</td>
                                            <td style='border: 1px solid black;'>$80</td>
                                            <td style='border: 1px solid black;'>$40,000</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p style='margin: 20px 0 0 0;'>Freight:</p>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Materials</th>
                                            <th style='border: 1px solid black;'>Freight Price</th>
                                            <th style='border: 1px solid black;'>Lead Time</th>
                                            <th style='border: 1px solid black;'>Source</th>
                                            <th style='border: 1px solid black;'>Freight Carrier</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>Aluminum 6061-T6 Sheet</td>
                                            <td style='border: 1px solid black;'>$500</td>
                                            <td style='border: 1px solid black;'>1 - 2 days</td>
                                            <td style='border: 1px solid black;'>Austin</td>
                                            <td style='border: 1px solid black;'>XPO Logistics</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br>
                        Total Material Price: $40,000<br>
                        Taxes: $3,200<br>
                        Total Freight Price: $500<br>
                        Total Order Price: $43,700<br>
                        Shipping Location: 2375 Sierra Ct Palo Alto, CA, 94303<br><br>
                        Do not hesitate to contact us if you need more assistance.<br>
                        `,

                vendors: ['Kloeckner Metals', 'Alro', 'Metal Supermarkets'],
                vendorSubject: ['Subject": "Request for Quote on Brass Material,'],
                vendorResponse: `
                        I hope this email finds you well. My name is John, and I'm reaching out to request a quote for the following materials we’re interested in procuring:<br><br>
                        
                        Material 1: Brass<br>
                        - Material Type: Brass<br>
                        - Grade or Alloy: Zn 99.995%<br>
                        - Shape/Form: Sheet<br>
                        - Thickness: 2mm<br>
                        - Quantity: 350 sheets<br>
                        - Unit of Measure: pcs<br><br>
                        
                        Material 2: Stainless Steel<br>
                        - Material Type: Stainless Steel<br>
                        - Grade or Alloy: 304<br>
                        - Shape/Form: Bar<br>
                        - Diameter: 25mm<br>
                        - Thickness: N/A<br>
                        - Length: 3 meters<br>
                        - Quantity: 200 bars<br>
                        - Unit of Measure: pcs<br><br>
                        
                        Additional Information Required:<br>
                        - Lead Time:<br>
                        - Minimum prepaid freight policy:<br>
                        - Minimum Order Quantity (MOQ):<br>
                        - Price brackets:<br><br>
                        
                        We would greatly appreciate your prompt response to this request. Your information will assist us in making our procurement decisions.<br><br>
                        
                        Looking forward to doing business with you.<br><br>
                        
                        Best regards,<br>
                        Ryan
                        `,

                materials: ["Aluminum, Stainless Steel, Copper, Brass"],
                quotePrice: "$102394",
                automationStatus: "Quote Email Ready to Send"
            },
            {
                rfq: 'RFQ019314978563236',
                industry: 'Metals',
                id: '1',
                sender: 'Ryan Alu',
                senderEmail: 'ryan.a@wise-sales.com',
                recipients: ['Alice Johnson'],
                cc: ['John Doe'],
                subject: 'Adjustment Request for Metal Sheet Dimensions',
                content: `Hello Santiago,<br>
                        I hope you’re doing well. I’m reaching out regarding the aluminum sheet I recently ordered. After reviewing the project specifications, I’ve realized that the current size of 48" x 96" is a bit too large for my application.<br><br>
                        To ensure it fits perfectly within my project parameters, I would like to request that you trim 2 inches off the length, resulting in a final size of 48" x 94".<br><br>
                        Please update the order to reflect the following:<br>
                        Quantity: 1 sheet<br>
                        Material: Aluminum<br>
                        Alloy: 6061-T6<br>
                        Thickness: 1/4 inch<br>
                        Dimensions: 48 inches x 94 inches<br><br>
                        I appreciate your help with this adjustment, as it will make a significant difference in the outcome of my project. Your attention to detail is always valued.<br><br>
                        Thank you very much,<br><br>
                        Ryan Alu<br>
                        Wise Sales<br>
                        18096 Idalyn Drive<br>
                        Los Gatos, CA 95033`,

                date: '2024-03-16',
                destination: '18096 Idalyn Drive Los Gatos, CA 95033',
                company: 'Esmark Steel Group',
                shipments: [
                    {
                        best_quote: {
                            CarrierName: "XPO Logistics",
                            CustomerPrice: {
                                NetPrice: 176.28,
                                FreightCharge: 134.31,
                                GrossCharge: 901.81,
                                Markup: 2.92,
                                margin: 35,
                                margin_on_freight_cost: 271.2,
                                lead_time: "1 - 2 days"
                            },
                        },

                        all_quotes: [
                            {
                                CarrierName: "XPO Logistics",
                                CustomerPrice: {
                                    NetPrice: 176.28,
                                    FreightCharge: 134.31,
                                    margin: 35,
                                    margin_on_freight_cost: 271.2,
                                    lead_time: "1 - 2 days"
                                },
                            },
                            {
                                CarrierName: "XPO Logistics Freight, Inc. - Guaranteed",
                                CustomerPrice: {
                                    NetPrice: 255.03,
                                    FreightCharge: 133.11,
                                    margin: 35,
                                    margin_on_freight_cost: 392.35,
                                    lead_time: "1 - 2 days"
                                },
                            },
                        ],

                        payload: {
                            Shipper: {
                                AddressInfo: {
                                    Address1: "3714 Bluestein Dr. Suite 790",
                                    Address2: "",
                                    City: "Austin",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "TX",
                                    Zipcode: "78721",
                                    LocationName: "Interstate Advanced Materials Austin"
                                },
                                CompanyName: "Interstate Advanced Materials Austin",
                                LocationName: "Interstate Advanced Materials Austin",

                            },
                            Consignee: {
                                ContactInfo: {
                                    FirstName: "Ryan",
                                    LastName: "Kimichick",
                                    ContactName: "Ryan Kimichick",
                                    Email: "santiago.c@interstateAM.com"
                                },
                                AddressInfo: {
                                    Address1: "18096 Idalyn Drive",
                                    Address2: null,
                                    City: "Los Gatos",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "CA",
                                    Zipcode: "95033",
                                    LocationName: "Wise Sales"
                                },
                            },
                            Products: [
                                {
                                    ProductCode: "POLCE  SH00118x048000x0096000",
                                    warehouse: "D1",
                                    Description: "ALU6061T60004",
                                    Length: "96.000",
                                    Width: "48.000",
                                    Height: "0.118",
                                    Quantity: "10",
                                    UnitOfMeasure: "EA",
                                }
                            ],

                        },
                    },

                    {
                        best_quote: {
                            CarrierName: "Estes Express Lines (LTL Standard Transit)",
                            CustomerPrice: {
                                NetPrice: 163.04,
                                FreightCharge: 118.64,
                                margin: 35,
                                margin_on_freight_cost: 250.83,
                                lead_time: "2 - 4 days"
                            },
                        },
                        all_quotes: [
                            {
                                CarrierName: "Estes Express Lines (LTL Standard Transit)",
                                CustomerPrice: {
                                    NetPrice: 163.04,
                                    FreightCharge: 118.64,
                                    margin: 35,
                                    margin_on_freight_cost: 250.83,
                                    lead_time: "2 - 4 days"
                                },
                            },
                            {
                                CarrierName: "Estes Express Lines (Guaranteed LTL Standard Transit: 5PM)",
                                CustomerPrice: {
                                    NetPrice: 213.04,
                                    FreightCharge: 168.97,
                                    margin: 35,
                                    margin_on_freight_cost: 327.75,
                                    lead_time: "2 - 4 days"
                                },
                            },
                        ],

                        payload: {
                            Shipper: {
                                AddressInfo: {
                                    Address1: "3562 South TK Ave.",
                                    Address2: "",
                                    City: "Boise",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "ID",
                                    Zipcode: "83705",
                                    LocationName: "Interstate Advanced Materials Boise"
                                },
                                CompanyName: "Interstate Advanced Materials Boise",
                            },
                            Consignee: {
                                AddressInfo: {
                                    Address1: "18096 Idalyn Drive",
                                    Address2: null,
                                    City: "Los Gatos",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "CA",
                                    Zipcode: "95033",
                                    LocationName: "Wise Sales"
                                },
                                CompanyName: "Wise Sales",
                            },
                            Products: [
                                {
                                    ProductCode: "HDPBE  SH00187x020000x0096000",
                                    warehouse: "C1",
                                    Description: "STASTEEL3040025x3",
                                    Length: "96.000",
                                    Width: "20.000",
                                    Height: "0.187",
                                    Quantity: "20",
                                    UnitOfMeasure: "EA",
                                    PackageType: 29,
                                    Class: 3,
                                }
                            ],

                        },
                    },

                ],

                tableData: [
                    {
                        cutting: [
                            {
                                statusCutting: true,
                                overallyield: '98',
                                totalCuttingCost: '50',
                            }
                        ],
                        stockStatus: 'InStock',
                        id:'45874',
                        data: [
                            {label: 'Material Type', value: 'Aluminum'},
                            {label: 'Alloy', value: '6061-T6'},
                            {label: 'Shape/Form', value: 'Sheet'},
                            {label: 'Diameter', value: 'N/A'}, // Not applicable for sheets
                            {label: 'Thickness', value: '1/4 inch'},
                            {label: 'Length', value: '94 inches'},
                            {label: 'Width', value: '48 inches'},
                            {label: 'Color/Finish', value: 'N/A'}, // Usually silver or grey
                            {label: 'Quantity', value: '1 sheet'},
                            {label: 'Unit of Measure', value: 'sheet'}, // Sheet
                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: 'Austin'},
                            {label: 'Dimension', value: '0.22 x 48 x 94'},
                            {label: 'Quantity', value: '1'},
                            {label: 'Quantity Unit', value: 'EA'},
                            {label: 'ERP Code', value: 'ALUM022x48x94'},
                            {label: 'Material Cost', value: '85'},
                            {label: 'Margin', value: '24'},
                            {label: 'Price', value: ' 167.4'},
                        ]
                    },
                ],
                customerResponse: `
                       Hello Ryan,<br><br>
                        Thank you for your ongoing support.<br><br>                    
                        Here's the quote you requested.<br><br>
                        <div id='disable-edit'>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Material</th>
                                            <th style='border: 1px solid black;'>Dimensions</th>
                                            <th style='border: 1px solid black;'>Quantity</th>
                                            <th style='border: 1px solid black;'>Color</th>
                                            <th style='border: 1px solid black;'>Availability</th>
                                            <th style='border: 1px solid black;'>Unit Price</th>
                                            <th style='border: 1px solid black;'>Total Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>Aluminum 6061-T6 Sheet</td>
                                            <td style='border: 1px solid black;'>4mm</td>
                                            <td style='border: 1px solid black;'>500 sheets</td>
                                            <td style='border: 1px solid black;'>Natural</td>
                                            <td style='border: 1px solid black;'>In-Stock</td>
                                            <td style='border: 1px solid black;'>$80</td>
                                            <td style='border: 1px solid black;'>$40,000</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p style='margin: 20px 0 0 0;'>Freight:</p>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Materials</th>
                                            <th style='border: 1px solid black;'>Freight Price</th>
                                            <th style='border: 1px solid black;'>Lead Time</th>
                                            <th style='border: 1px solid black;'>Source</th>
                                            <th style='border: 1px solid black;'>Freight Carrier</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>Aluminum 6061-T6 Sheet</td>
                                            <td style='border: 1px solid black;'>$500</td>
                                            <td style='border: 1px solid black;'>1 - 2 days</td>
                                            <td style='border: 1px solid black;'>Austin</td>
                                            <td style='border: 1px solid black;'>XPO Logistics</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br>
                        Total Material Price: $40,000<br>
                        Taxes: $3,200<br>
                        Total Freight Price: $500<br>
                        Total Order Price: $43,700<br>
                        Shipping Location: 18096 Idalyn Drive, Los Gatos, CA 95033<br><br>
                        Do not hesitate to contact us if you need more assistance.<br>`,

                vendors: [''],
                vendorSubject: ['Subject": "Request for Quote on Zinc and Bronze Material,'],
                vendorResponse: ``,

                materials: ["Aluminum"],
                quotePrice: "$16658",
                automationStatus: "Quote Email Ready to Send"
            },
            {
                rfq: 'RFQ0193487985241',
                industry: 'Metals',
                id: '2',
                sender: 'Ryan Alu',
                senderEmail: 'ryan.a@wise-sales.com',
                recipients: ['Santiago Campo'],
                cc: ['John Doe'],
                subject: 'Copper requirement',
                content: `Hello Santiago,<br>

                I hope you’re doing well. I’m reaching out regarding the copper sheet I recently ordered. After reviewing the project specifications, I would like to confirm the order details to ensure everything meets my requirements.<br><br>
            
                Here are the specifics:<br><br>
            
                - Quantity: 1 sheet<br>
                - Material: Copper<br>
                - Alloy: C11000 (Electrolytic Tough Pitch Copper)<br>
                - Thickness: 1/4 inch<br>
                - Dimensions: 48 inches x 96 inches<br><br>
            
                Please let me know if you need any additional information or if there are any adjustments required.<br><br>
            
                I appreciate your assistance with this order. Thank you very much!<br><br>
            
                Best regards,<br><br>
            
                Ryan Alu<br>
                Wise Sales<br>
               `,

                date: '2024-03-16',
                destination: '18096 Idalyn Drive Los Gatos, CA 95033',
                company: 'Esmark Steel Group',
                shipments: [
                    {
                        best_quote: {
                            CarrierName: "",
                            CustomerPrice: {
                                NetPrice: 176.28,
                                FreightCharge:null ,
                                GrossCharge: null,
                                Markup: null,
                                margin: null,
                                margin_on_freight_cost: null,
                                lead_time: ""
                            },
                        },

                        all_quotes: [
                            {
                                CarrierName: "",
                                CustomerPrice: {
                                    NetPrice: null,
                                    FreightCharge: null,
                                    margin:null,
                                    margin_on_freight_cost: null,
                                    lead_time: ""
                                },
                            },
                            {
                                CarrierName: "",
                                CustomerPrice: {
                                    NetPrice: null,
                                    FreightCharge: null,
                                    margin: null,
                                    margin_on_freight_cost: null,
                                    lead_time: ""
                                },
                            },
                        ],

                        payload: {
                            Shipper: {
                                AddressInfo: {
                                    Address1: "",
                                    Address2: "",
                                    City: "Austin",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "TX",
                                    Zipcode: "78721",
                                    LocationName: "Interstate Advanced Materials Austin"
                                },
                                CompanyName: "Interstate Advanced Materials Austin",
                                LocationName: "Interstate Advanced Materials Austin",

                            },
                            Consignee: {
                                ContactInfo: {
                                    FirstName: "Ryan",
                                    LastName: "Kimichick",
                                    ContactName: "Ryan Kimichick",
                                    Email: "santiago.c@interstateAM.com"
                                },
                                AddressInfo: {
                                    Address1: "",
                                    Address2: null,
                                    City: "Los Gatos",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "CA",
                                    Zipcode: "95033",
                                    LocationName: "Wise Sales"
                                },
                            },
                            Products: [
                                {
                                    ProductCode: "POLCE  SH00118x048000x0096000",
                                    warehouse: "D1",
                                    Description: "CLEAR POLYCARB SHEET",
                                    Length: "96.000",
                                    Width: "48.000",
                                    Height: "0.118",
                                    Quantity: "10",
                                    UnitOfMeasure: "EA",
                                }
                            ],

                        },
                    },
                ],

                tableData: [
                    {
                        cutting: [
                            {
                                statusCutting: false,
                                overallyield: '',
                                totalCuttingCost: '',
                            }
                        ],
                        stockStatus: 'InStock',
                        id: '458923',
                        data: [
                            {label: 'Material Type', value: 'Copper'},
                            {label: 'Alloy', value: 'C11000 (Electrolytic Tough Pitch Copper)'},
                            {label: 'Shape/Form', value: 'Sheet'},
                            {label: 'Diameter', value: 'N/A'}, // Not applicable for sheets
                            {label: 'Thickness', value: '1/4 inch'},
                            {label: 'Length', value: '96 inches'},
                            {label: 'Width', value: '48 inches'},
                            {label: 'Color/Finish', value: 'N/A'}, // Usually copper color
                            {label: 'Quantity', value: '1 sheet'},
                            {label: 'Unit of Measure', value: 'sheet'}, // Sheet
                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: 'Austin'},
                            {label: 'Dimension', value: '0.25 x 48 x 96'}, // Adjusted for 1/4 inch thickness
                            {label: 'Quantity', value: '1'},
                            {label: 'Quantity Unit', value: 'EA'},
                            {label: 'ERP Code', value: 'COPPER048x96'}, // Adjusted for copper
                            {label: 'Material Cost', value: '85'}, // Example cost
                            {label: 'Margin', value: '24'}, // Example margin
                            {label: 'Price', value: '167.4'}, // Example price
                        ]
                    },
                ],

                customerResponse: `
                        Hello Santiago,<br><br>

                        I hope you’re doing well. I’m reaching out regarding the copper sheet I recently ordered. Could you please confirm the shipping address for this order?<br><br>
                        
                        Thank you!<br><br>
                        
                        Best,<br>
                        Santiago Campo<br>
                        Wise Sales
                        `,

                vendors: [''],
                vendorSubject: ['Subject": "Request for Quote on Zinc and Bronze Material,'],
                vendorResponse: ``,

                materials: ["Copper"],
                quotePrice: "$1000",
                automationStatus: "Clarification Email Ready to Send"
            }
        ]
    },
    {
        image: '/images/electronic.jpg',
        title: 'Electronic Components',
        id: '1',
         dashboardTable : [
            { material: 'Resistor', stockCode: 'R100', price: '$0.10' },
            { material: 'Capacitor', stockCode: 'C220', price: '$0.15' },
            { material: 'Inductor', stockCode: 'L330', price: '$0.25' },
            { material: 'Transistor', stockCode: 'T440', price: '$0.50' },
            { material: 'Diode', stockCode: 'D550', price: '$0.05' },
            { material: 'LED', stockCode: 'LED660', price: '$0.20' },
            { material: 'Voltage Regulator', stockCode: 'VR7805', price: '$1.00' },
            { material: 'Microcontroller', stockCode: 'MCU123', price: '$2.50' },
        ],

        emails: [
            {
                rfq: 'RFQ01956981',
                industry: 'Electronic Components',
                id: '0',
                sender: 'Mateo M',
                senderEmail: 'mateo.m@wise-sales.com',
                recipients: ['Jane Smith'],
                cc: ['Alice Johnson, Ryan Alu'],
                subject: 'Quote Needed for Electronic Components',
                content: `Hi Jane Smith<br><br>
                    I hope this email finds you well. I am reaching out on behalf of WESCO to request a quote for various electronic components. We are keen to establish a reliable supplier relationship and have heard excellent reviews about Wise-Components' services.<br><br>
                    Components Required:<br><br>
                    Microcontrollers<br><br>
                    Type: ATmega328P<br>
                    Quantity: 1000 units<br><br>
                    Capacitors<br><br>
                    Type: Ceramic<br>
                    Value: 10uF<br>
                    Quantity: 2000 units<br><br>
                    Resistors<br><br>
                    Type: 1/4W Carbon Film<br>
                    Value: 220 Ohm<br>
                    Quantity: 5000 units<br><br>
                    If you have any questions or require further clarification on our requirements, please do not hesitate to contact me at +1 (420)-225-1216 or via email.<br><br>
                    Thank you for your attention to this matter. We look forward to receiving your competitive quote.<br><br>
                    Warm regards,<br>
                    Mateo Maldonado,<br>
                    2375 Sierra Ct<br>
                    Palo Alto, CA, 94303`,

                destination: '2375 Sierra Ct Palo Alto, CA, 94303',
                date: '2024-04-23',
                company: 'WESCO',
                tableData: [
                    {
                        cutting: [
                            {
                                statusCutting: false,
                                overallyield: '0',
                                totalCuttingCost: '0',
                            }
                        ],
                        stockStatus: 'InStock',
                        data: [
                            {label: 'Component Type', value: 'Microcontrollers'},
                            {label: 'Type', value: 'ATmega328P'},
                            {label: 'Value', value: ''},
                            {label: 'Quantity', value: '1000 units'},
                            {label: 'Unit of Measure', value: 'Pack'}, // Sheet

                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: 'Austin'},
                            {label: 'Component Type', value: 'Microcontrollers'},
                            {label: 'Type', value: 'ATmega328P'},
                            {label: 'Quantity', value: '10000'},
                            {label: 'ERP Code', value: 'MicroATMega'},
                            {label: 'Component Cost', value: '2'},
                            {label: 'Margin', value: '20'},
                            {label: 'Price', value: '3000'},

                        ]
                    },
                    {
                        stockStatus: 'PartialStock',
                        data: [
                            {label: 'Component Type', value: 'Capacitors'},
                            {label: 'Type', value: 'Ceramic'},
                            {label: 'Value', value: '10uF'},
                            {label: 'Quantity', value: '2000 units'},
                            {label: 'Unit of Measure', value: 'Pack'}, // Sheet

                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: 'Austin'},
                            {label: 'Component Type', value: 'Capacitors'},
                            {label: 'Type', value: 'Ceramic'},
                            {label: 'Quantity', value: '10000'},
                            {label: 'ERP Code', value: 'CapCeramic10'},
                            {label: 'Component Cost', value: '6'},
                            {label: 'Margin', value: '20'},
                            {label: 'Price', value: '3000'},
                        ]
                    },
                    {
                        stockStatus: 'OutOfStock',
                        data: [
                            {label: 'Component Type', value: 'Resistors'},
                            {label: 'Type', value: '1/4W Carbon Film'},
                            {label: 'Value', value: '200 Ohm'},
                            {label: 'Quantity', value: '5000 units'},
                            {label: 'Unit of Measure', value: 'Piece'}, // Sheet

                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: ''},
                            {label: 'Component Type', value: 'Resistors'},
                            {label: 'Type', value: '1/4W Carbon Film'},
                            {label: 'Quantity', value: '0'},
                            {label: 'ERP Code', value: 'ResistCarbon200'},
                            {label: 'Component Cost', value: '10'},
                            {label: 'Margin', value: '20'},
                            {label: 'Price', value: '3000'},
                        ]

                    },
                    {
                        stockStatus: 'InStockAlternativeLocations',
                        data: [
                            {label: 'Component Type', value: 'Transistor'},
                            {label: 'Type', value: 'NPN'},
                            {label: 'Value', value: '2n222'},
                            {label: 'Quantity', value: '1000 units'},
                            {label: 'Unit of Measure', value: 'Piece'}, // Sheet

                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Sister Location', value: 'Miami'},
                            {label: 'Component Type', value: 'Transistor'},
                            {label: 'Type', value: 'NPN'},
                            {label: 'Quantity', value: '1000'},
                            {label: 'ERP Code', value: 'T-2N2222'},
                            {label: 'Component Cost', value: '15'},
                            {label: 'Margin', value: '20'},
                            {label: 'Price', value: '3000'},
                        ]
                    },
                ],

                shipments: [
                    {
                        best_quote: {
                            CarrierName: "XPO Logistics",
                            CustomerPrice: {
                                NetPrice: 176.28,
                                FreightCharge: 134.31,
                                GrossCharge: 901.81,
                                Markup: 2.92,
                                margin: 35,
                                margin_on_freight_cost: 271.2,
                                lead_time: "1 - 2 days"
                            },
                        },

                        all_quotes: [
                            {
                                CarrierName: "XPO Logistics",
                                CustomerPrice: {
                                    NetPrice: 176.28,
                                    FreightCharge: 134.31,
                                    margin: 35,
                                    margin_on_freight_cost: 271.2,
                                    lead_time: "1 - 2 days"
                                },
                            },
                            {
                                CarrierName: "XPO Logistics Freight, Inc. - Guaranteed",
                                CustomerPrice: {
                                    NetPrice: 255.03,
                                    FreightCharge: 133.11,
                                    margin: 35,
                                    margin_on_freight_cost: 392.35,
                                    lead_time: "1 - 2 days"
                                },
                            },
                        ],

                        payload: {
                            Shipper: {
                                AddressInfo: {
                                    Address1: "3714 Bluestein Dr. Suite 790",
                                    Address2: "",
                                    City: "Austin",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "TX",
                                    Zipcode: "78721",
                                    LocationName: "Interstate Advanced Materials Austin"
                                },
                                CompanyName: "Interstate Advanced Materials Austin",
                                LocationName: "Interstate Advanced Materials Austin",

                            },
                            Consignee: {
                                ContactInfo: {
                                    FirstName: "Ryan",
                                    LastName: "Kimichick",
                                    ContactName: "Ryan Kimichick",
                                    Email: "santiago.c@interstateAM.com"
                                },
                                AddressInfo: {
                                    Address1: "18096 Idalyn Drive",
                                    Address2: null,
                                    City: "Los Gatos",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "CA",
                                    Zipcode: "95033",
                                    LocationName: "Wise Sales"
                                },
                            },
                            Products: [
                                {
                                    ProductCode: "POLCE  SH00118x048000x0096000",
                                    warehouse: "D1",
                                    Description: "MicroATMega",
                                    Length: "96.000",
                                    Width: "48.000",
                                    Height: "0.118",
                                    Quantity: "10",
                                    UnitOfMeasure: "EA",
                                }
                            ],

                        },
                    },

                    {
                        best_quote: {
                            CarrierName: "Estes Express Lines (LTL Standard Transit)",
                            CustomerPrice: {
                                NetPrice: 163.04,
                                FreightCharge: 118.64,
                                margin: 35,
                                margin_on_freight_cost: 250.83,
                                lead_time: "2 - 4 days"
                            },
                        },
                        all_quotes: [
                            {
                                CarrierName: "Estes Express Lines (LTL Standard Transit)",
                                CustomerPrice: {
                                    NetPrice: 163.04,
                                    FreightCharge: 118.64,
                                    margin: 35,
                                    margin_on_freight_cost: 250.83,
                                    lead_time: "2 - 4 days"
                                },
                            },
                            {
                                CarrierName: "Estes Express Lines (Guaranteed LTL Standard Transit: 5PM)",
                                CustomerPrice: {
                                    NetPrice: 213.04,
                                    FreightCharge: 168.97,
                                    margin: 35,
                                    margin_on_freight_cost: 327.75,
                                    lead_time: "2 - 4 days"
                                },
                            },
                        ],

                        payload: {
                            Shipper: {
                                AddressInfo: {
                                    Address1: "3562 South TK Ave.",
                                    Address2: "",
                                    City: "Boise",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "ID",
                                    Zipcode: "83705",
                                    LocationName: "Interstate Advanced Materials Boise"
                                },
                                CompanyName: "Interstate Advanced Materials Boise",
                            },
                            Consignee: {
                                AddressInfo: {
                                    Address1: "18096 Idalyn Drive",
                                    Address2: null,
                                    City: "Los Gatos",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "CA",
                                    Zipcode: "95033",
                                    LocationName: "Wise Sales"
                                },
                                CompanyName: "Wise Sales",
                            },
                            Products: [
                                {
                                    ProductCode: "HDPBE  SH00187x020000x0096000",
                                    warehouse: "C1",
                                    Description: "CapCeramic10",
                                    Length: "96.000",
                                    Width: "20.000",
                                    Height: "0.187",
                                    Quantity: "20",
                                    UnitOfMeasure: "EA",
                                    PackageType: 29,
                                    Class: 3,
                                }
                            ],

                        },
                    },

                ],

                customerResponse: `
                       Hello Mateo Maldonado,<br><br>
                        Thank you for your inquiry regarding the electronic components.<br><br>
                        Please be assured that I am working on securing the components that are currently unavailable:<br>
                            - Capacitors (Type: Ceramic, Quantity: 5000 units)<br>
                            - Resistors (Type: 1/4W Carbon Film, Value: 220 Ohm, Quantity: 5000 units)<br><br>
                        Here's the quote you requested.<br><br>
                        <div id='disable-edit'>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Component Type</th>
                                            <th style='border: 1px solid black;'>Type</th>
                                            <th style='border: 1px solid black;'>Quantity</th>
                                            <th style='border: 1px solid black;'>Unit Price</th>
                                            <th style='border: 1px solid black;'>Total Price</th>
                                            <th style='border: 1px solid black;'>Availability</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>Microcontrollers</td>
                                            <td style='border: 1px solid black;'>ATmega328P</td>
                                            <td style='border: 1px solid black;'>1000 units</td>
                                            <td style='border: 1px solid black;'>$2</td>
                                            <td style='border: 1px solid black;'>$2,000</td>
                                            <td style='border: 1px solid black;'>In-Stock</td>
                                        </tr>
                                        <tr>
                                            <td style='border: 1px solid black;'>Capacitors</td>
                                            <td style='border: 1px solid black;'>Ceramic (10uF)</td>
                                            <td style='border: 1px solid black;'>5000 units</td>
                                            <td style='border: 1px solid black;'>$0.50</td>
                                            <td style='border: 1px solid black;'>$1,000</td>
                                            <td style='border: 1px solid black;'>In-Stock</td>
                                        </tr>
                                      
                                    </tbody>
                                </table>
                            </div>
                            <p style='margin: 20px 0 0 0;'>Freight:</p>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Component</th>
                                            <th style='border: 1px solid black;'>Freight Price</th>
                                            <th style='border: 1px solid black;'>Lead Time</th>
                                            <th style='border: 1px solid black;'>Source</th>
                                            <th style='border: 1px solid black;'>Freight Carrier</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>Electronics Components</td>
                                            <td style='border: 1px solid black;'>$300</td>
                                            <td style='border: 1px solid black;'>1 - 3 days</td>
                                            <td style='border: 1px solid black;'>San Francisco</td>
                                            <td style='border: 1px solid black;'>FedEx</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br>
                        Total Material Price: $3,500<br>
                        Taxes: $280<br>
                        Total Freight Price: $300<br>
                        Total Order Price: $4,080<br>
                        Shipping Location: 2375 Sierra Ct, Palo Alto, CA 94303<br><br>
                        Do not hesitate to contact us if you need more assistance.<br>
                        `,

                vendors: ['Mouser Electronics', 'DigiKey', 'Arrow Electronics'],
                vendorSubject: ['Subject": "Request for Quote on Resistors,'],
                vendorResponse:
                `I hope this email finds you well.<br><br>
                 My name is Ryan, and I'm reaching out to request a quote for the following materials we’re interested in procuring:<br><br>

                        Component: Resistors<br>
                        - Component Type:  1/4W Carbon Film<br>
                        - Value:200 Ohm<br>
                        - Quantity:5000 Units<br><br>

                        Additional Information Required:<br>
                        - Lead Time:<br>
                        - Minimum prepaid freight policy:<br>
                        - Minimum Order Quantity (MOQ):<br>
                        - Price brackets:<br><br>

                        We would greatly appreciate your prompt response to this request. Your information will assist us in making our procurement decisions.<br><br>

                        Looking forward to doing business with you.<br><br>

                        Best regards,<br>
                        Ryan
                        `,
                receivedQuotes: [
                    {
                        vendorName: 'Honeywell',
                        response:
                            'Subject: RFQ-04/28/2024' +
                            '\n\n' +
                            'Dear John,\n' +
                            '\n' +
                            'Thank you for reaching out to Honeywell for your material needs. Here is our quote based on your requirements:\n' +
                            '\n' +
                            'Component: Resistors\n' +
                            'Component Type: 1/4W Carbon Film\n' +
                            'Value:200 Ohm\n' +
                            'Quantity:5000 Units\n' +
                            'Price: $5000\n' +
                            '\n' +
                            'Lead Time: 1 week\n' +
                            'Minimum prepaid freight policy: $100\n' +
                            'Minimum Order Quantity (MOQ): 1000 Units\n' +
                            'Price brackets: $4500 (1000 Units), $4800 (2000 Units)\n' +
                            '\n' +
                            'We look forward to your response and are eager to do business with you.\n' +
                            '\n' +
                            'Best regards,\n' +
                            'Santiago C\n' +
                            'Honeywell'
                    },
                    {
                        vendorName: 'NXP Semiconductors',
                        response:
                            'Subject: RFQ-04/27/2024' +
                            '\n\n' +
                            'Dear John,\n' +
                            '\n' +
                            'Thank you for reaching out to NXP Semiconductors for your material needs. Here is our quote based on your requirements:\n' +
                            '\n' +
                            'Component: Resistors\n' +
                            'Component Type: 1/4W Carbon Film\n' +
                            'Value:200 Ohm\n' +
                            'Quantity:5000 Units\n' +
                            'Price: $5000\n' +
                            '\n' +
                            'Lead Time: 1 week\n' +
                            'Minimum prepaid freight policy: $100\n' +
                            'Minimum Order Quantity (MOQ): 1000 Units\n' +
                            'Price brackets: $4500 (1000 Units), $4800 (2000 Units)\n' +
                            '\n' +
                            'We look forward to your response and are eager to do business with you.\n' +
                            '\n' +
                            'Best regards,\n' +
                            'Ryan A\n' +
                            'NXP Semiconductors'
                    },
                    {
                        vendorName: 'TDK',
                        response:
                            'Subject: RFQ-04/30/2024' +
                            '\n\n' +
                            'Dear John,\n' +
                            '\n' +
                            'Thank you for reaching out to TDK for your material needs. Here is our quote based on your requirements:\n' +
                            '\n' +
                            'Component: Resistors\n' +
                            'Component Type: 1/4W Carbon Film\n' +
                            'Value:200 Ohm\n' +
                            'Quantity:5000 Units\n' +
                            'Price: $5000\n' +
                            '\n' +
                            'Lead Time: 1 week\n' +
                            'Minimum prepaid freight policy: $100\n' +
                            'Minimum Order Quantity (MOQ): 1000 Units\n' +
                            'Price brackets: $4500 (1000 Units), $4800 (2000 Units)\n' +
                            '\n' +
                            'We look forward to your response and are eager to do business with you.\n' +
                            '\n' +
                            'Best regards,\n' +
                            'Ryan K\n' +
                            'TDK'
                    }
                ],

                materials: ["Resistors, Transistors, Capacitors, Microcontrollers"],
                quotePrice: "$15784",
                automationStatus: "Quote Email Ready to Send"

            },
            {
                rfq: 'RFQ0164587',
                industry: 'Electronic Components',
                id: '1',
                sender: 'Juan Arguelles',
                senderEmail: 'ryan.k@wise-sales.com',
                recipients: ['Santiago Campo'],
                cc: ['John Doe', 'Jane Smith'],
                subject: 'Request for Quote on Electronic Components',
                content: `Hi Santiago,<br><br>
                I hope this email finds you well. I am reaching out on behalf of Suntronic to request a quote for various metal supplies. We are keen to establish a reliable supplier relationship and have heard excellent reviews about Wise-Sales' services.<br><br>
                Components Required:<br><br>
               
                Diodes<br>
                Type: 1N4148<br>
                Quantity: 1000 units<br><br>
                LED Displays<br>
                Type: 7-Segment<br>
                Quantity: 300 units<br><br>
                If you have any questions or require further clarification on our requirements, please do not hesitate to contact me at +1 (420)-225-1215 or via email.<br><br>
                Thank you for your attention to this matter. We look forward to receiving your competitive quote.<br><br>
                Warm regards,<br>
                Juan Arguelles,<br>`
                ,
                destination: '2375 Sierra Ct Palo Alto, CA, 94303',
                date: '2024-05-16',
                company: 'Suntronic',
                tableData: [
                    {
                        stockStatus: 'PartialStock',
                        data: [
                            { label: 'Component Type', value: 'Diodes' },
                            { label: 'Type', value: '1N4148' },
                            { label: 'Value', value: '' },
                            { label: 'Quantity', value: '1000' },
                            { label: 'Unit of Measure', value: 'units' }, // New label for Unit of Measure
                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Type', value: '1N4148'},
                            {label: 'Quantity', value: '1000'},
                            {label: 'ERP Code', value: 'DIO1N41148'},
                            {label: 'Component Cost', value: '20'},
                            {label: 'Margin', value: '20'},
                            {label: 'Price', value: '3000'}
                        ]
                    },
                    {
                        stockStatus: 'OutOfStock',
                        data: [
                            { label: 'Component Type', value: 'LED Displays' },
                            { label: 'Type', value: '7-Segment' },
                            { label: 'Value', value: '' },
                            { label: 'Quantity', value: '300' },
                            { label: 'Unit of Measure', value: 'units' }, // New label for Unit of Measure
                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Type', value: '7-Segment'},
                            {label: 'Quantity', value: '300'},
                            {label: 'ERP Code', value: 'LED7seg'},
                            {label: 'Component Cost', value: '2'},
                            {label: 'Margin', value: '25'},
                            {label: 'Price', value: '3000'},
                        ]
                    },
                ],
                shipments: [
                    {
                        best_quote: {
                            CarrierName: "",
                            CustomerPrice: {
                                NetPrice: 176.28,
                                FreightCharge:null ,
                                GrossCharge: null,
                                Markup: null,
                                margin: null,
                                margin_on_freight_cost: null,
                                lead_time: ""
                            },
                        },

                        all_quotes: [
                            {
                                CarrierName: "",
                                CustomerPrice: {
                                    NetPrice: null,
                                    FreightCharge: null,
                                    margin:null,
                                    margin_on_freight_cost: null,
                                    lead_time: ""
                                },
                            },
                            {
                                CarrierName: "",
                                CustomerPrice: {
                                    NetPrice: null,
                                    FreightCharge: null,
                                    margin: null,
                                    margin_on_freight_cost: null,
                                    lead_time: ""
                                },
                            },
                        ],

                        payload: {
                            Shipper: {
                                AddressInfo: {
                                    Address1: "",
                                    Address2: "",
                                    City: "Austin",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "TX",
                                    Zipcode: "78721",
                                    LocationName: "Interstate Advanced Materials Austin"
                                },
                                CompanyName: "Interstate Advanced Materials Austin",
                                LocationName: "Interstate Advanced Materials Austin",

                            },
                            Consignee: {
                                ContactInfo: {
                                    FirstName: "Ryan",
                                    LastName: "Kimichick",
                                    ContactName: "Ryan Kimichick",
                                    Email: "santiago.c@interstateAM.com"
                                },
                                AddressInfo: {
                                    Address1: "",
                                    Address2: null,
                                    City: "Los Gatos",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "CA",
                                    Zipcode: "95033",
                                    LocationName: "Wise Sales"
                                },
                            },
                            Products: [
                                {
                                    ProductCode: "POLCE  SH00118x048000x0096000",
                                    warehouse: "D1",
                                    Description: "CLEAR POLYCARB SHEET",
                                    Length: "96.000",
                                    Width: "48.000",
                                    Height: "0.118",
                                    Quantity: "10",
                                    UnitOfMeasure: "EA",
                                }
                            ],

                        },
                    },
                ],
                customerResponse: `
                        Hello Juan,<br><br>

                        I hope you’re doing well. I’m reaching out regarding the Led Displays and Diodes you recently ordered. Could you please confirm the shipping address for this order?<br><br>

                        Thank you!<br><br>

                        Best,<br>
                        Santiago Campo<br>
                        Wise Sales
                        `,

                vendors: ['Mouser Electronics', 'Arrow'],
                vendorSubject: ['Subject": "Request for Quote on Transistors and LED Displays,'],
                vendorResponse:
                    `I hope this email finds you well.<br><br>
                    My name is Santiago, and I'm reaching out to request a quote for the following materials we’re interested in procuring:<br><br>

                        Component: Diodes<br>
                        - Component Type:  1N4148<br>
                        - Quantity:500 Units<br><br>
                        
                        Component: LED Displays<br>
                        - Component Type:  7- Segment<br>
                        - Quantity:300 Units<br><br>

                        Additional Information Required:<br>
                        - Lead Time:<br>
                        - Minimum prepaid freight policy:<br>
                        - Minimum Order Quantity (MOQ):<br>
                        - Price brackets:<br><br>

                        We would greatly appreciate your prompt response to this request. Your information will assist us in making our procurement decisions.<br><br>

                        Looking forward to doing business with you.<br><br>

                        Best regards,<br>
                        Santiago Campo
                        `,

                receivedQuotes: [
                    {
                        vendorName: 'Analog Devices',
                        response:
                            'Subject: RFQ 04/22/2024' +
                            '\n\n' +
                            'Dear Alice,\n' +
                            '\n' +
                            'Thank you for reaching out to Analog Devices for your component needs. Here are our quotes based on your requirements:\n' +
                            '\n' +
                            'Component: Transistors\n' +
                            'Component Type: NPN\n' +
                            'Quantity: 800 units\n' +
                            'Price: $6000\n' +
                            '\n' +
                            'Lead Time: 1 week\n' +
                            'Minimum prepaid freight policy: $200\n' +
                            'Minimum Order Quantity (MOQ): 500 units\n' +
                            'Price brackets: $5000 (1000 units), $5500 (1500 units)\n' +
                            '\n' +
                            'Component: LED Displays\n' +
                            'Component Type: 7-Segment\n' +
                            'Quantity: 300 units\n' +
                            'Price: $1500\n' +
                            '\n' +
                            'Lead Time: 2 weeks\n' +
                            'Minimum prepaid freight policy: $50\n' +
                            'Minimum Order Quantity (MOQ): 200 units\n' +
                            'Price brackets: $1200 (500 units), $1400 (1000 units)\n' +
                            '\n' +
                            'We are excited about the possibility of working together.\n' +
                            '\n' +
                            'Best regards,\n' +
                            'Ryan\n' +
                            'Analog Devices'
                    },
                    {
                        vendorName: 'Samtec',
                        response:
                            'Subject: RFQ 04/22/2024' +
                            '\n\n' +
                            'Dear Alice,\n' +
                            '\n' +
                            'Thank you for reaching out to Samtec for your component needs. Here are our quotes based on your requirements:\n' +
                            '\n' +
                            'Component: Transistors\n' +
                            'Component Type: NPN\n' +
                            'Quantity: 800 units\n' +
                            'Price: $6000\n' +
                            '\n' +
                            'Lead Time: 1 week\n' +
                            'Minimum prepaid freight policy: $200\n' +
                            'Minimum Order Quantity (MOQ): 500 units\n' +
                            'Price brackets: $5000 (1000 units), $5500 (1500 units)\n' +
                            '\n' +
                            'Component: LED Displays\n' +
                            'Component Type: 7-Segment\n' +
                            'Quantity: 300 units\n' +
                            'Price: $1500\n' +
                            '\n' +
                            'Lead Time: 2 weeks\n' +
                            'Minimum prepaid freight policy: $50\n' +
                            'Minimum Order Quantity (MOQ): 200 units\n' +
                            'Price brackets: $1200 (500 units), $1400 (1000 units)\n' +
                            '\n' +
                            'We are excited about the possibility of working together.\n' +
                            '\n' +
                            'Best regards,\n' +
                            'Ryan\n' +
                            'Samtec'
                    },
                    {
                        vendorName: 'Panasonic',
                        response:
                            'Subject: RFQ 04/22/2024' +
                            '\n\n' +
                            'Dear Alice,\n' +
                            '\n' +
                            'Thank you for reaching out to Panasonic for your component needs. Here are our quotes based on your requirements:\n' +
                            '\n' +
                            'Component: Transistors\n' +
                            'Component Type: NPN\n' +
                            'Quantity: 800 units\n' +
                            'Price: $6000\n' +
                            '\n' +
                            'Lead Time: 1 week\n' +
                            'Minimum prepaid freight policy: $200\n' +
                            'Minimum Order Quantity (MOQ): 500 units\n' +
                            'Price brackets: $5000 (1000 units), $5500 (1500 units)\n' +
                            '\n' +
                            'Component: LED Displays\n' +
                            'Component Type: 7-Segment\n' +
                            'Quantity: 300 units\n' +
                            'Price: $1500\n' +
                            '\n' +
                            'Lead Time: 2 weeks\n' +
                            'Minimum prepaid freight policy: $50\n' +
                            'Minimum Order Quantity (MOQ): 200 units\n' +
                            'Price brackets: $1200 (500 units), $1400 (1000 units)\n' +
                            '\n' +
                            'We are excited about the possibility of working together.\n' +
                            '\n' +
                            'Best regards,\n' +
                            'Ryan\n' +
                            'Panasonic'
                    }
                ],

                materials: ["Diodes, Led Displays"],
                quotePrice: "$15458",
                automationStatus: "Clarification Email Ready to Send"
            }
        ]
    },
    // Add more industries and senderEmail data as needed
];

export default industryData;
