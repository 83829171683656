const erpSearchData = [
    {
        material: "Aluminum",
        alloy: "6061",
        industry: "Metals", // Add this line
        items: [
            {
                stockCode: "AL-12x5",
                description: "6061 Aluminum Sheet - 12\" x 5\" x 0.5\"",
                ERPUnit: "Box",
                unitCost: 15.75,
                warehouse: "Austin",
                quantity: 100,
                dimensions: {
                    thickness: "0.5",  // Thickness in inches
                    width: "12",       // Width in inches
                    length: "5"        // Length in inches
                }
            },
            {
                stockCode: "AL-10x4",
                description:"6061 Aluminum Plate - 10\" x 4\" x 0.75\"",
                ERPUnit: "Piece",
                unitCost: 25.50,
                warehouse: "Miami",
                quantity: 50,
                dimensions: {
                    thickness: "0.75", // Thickness in inches
                    width: "10",       // Width in inches
                    length: "4"        // Length in inches
                }
            }
        ]
    },
    {
        material: "Steel",
        alloy: "316",
        industry: "Metals", // Add this line
        items: [
            {
                stockCode: "ST-8x3",
                description:  "316 Stainless Steel Plate - 40\" x 0.5\" x 5\"",
                ERPUnit: "Set",
                unitCost: 120.00,
                warehouse: "Atlanta",
                quantity: 30,
                dimensions: {
                    thickness: "0.10", // Thickness in inches
                    width: "40",       // Width in inches
                    length: "5"        // Length in inches
                }
            },
            {
                stockCode: "ST-15x7",
                description: "316 Stainless Steel Sheet - 13\" x 0.4\" x 4\"",
                ERPUnit: "Box",
                unitCost: 95.50,
                warehouse: "New York",
                quantity: 60,
                dimensions: {
                    thickness: "0.4", // Thickness in inches
                    width: "13",       // Width in inches
                    length: "4"        // Length in inches
                }
            }
        ]
    },
    {
        material: "Copper",
        alloy: "C110",
        industry: "Metals", // Add this line
        items: [
            {
                stockCode: "CU-5x2",
                description: "Copper Amber 5x2",
                ERPUnit: "Piece",
                unitCost: 50.25,
                warehouse: "Austin",
                quantity: 80,
                dimensions: {
                    thickness: "0.75", // Thickness in inches
                    width: "10",       // Width in inches
                    length: "6"        // Length in inches
                }
            },
            {
                stockCode: "CU-7x3",
                description: "7x3",
                ERPUnit: "Roll",
                unitCost: 130.00,
                warehouse: "Miami",
                quantity: 45,
                dimensions: {
                    thickness: "0.115", // Thickness in inches
                    width: "12",       // Width in inches
                    length: "4"        // Length in inches
                }
            }
        ]
    },
    {
        material: "Resistor",
        type: "Carbon Film",
        industry: "Electronic Components",
        items: [
            {
                stockCode: "R-1kOhm",
                description: "1k Ohm Carbon Film Resistor - Standard Size",
                ERPUnit: "Pack",
                unitCost: 0.05,
                warehouse: "Austin",
                quantity: 5000,
                dimensions: { width: "0.25", thickness: "0.15", length: "0.8" } // Dimensions in inches
            },
            {
                stockCode: "R-10kOhm",
                description: "10k Ohm Carbon Film Resistor - Standard Size",
                ERPUnit: "Pack",
                unitCost: 0.07,
                warehouse: "Miami",
                quantity: 3000,
                dimensions: { width: "0.25", thickness: "0.15", length: "0.8" } // Dimensions in inches
            }
        ]
    },
    {
        material: "Capacitor",
        type: "Electrolytic",
        industry: "Electronic Components",
        items: [
            {
                stockCode: "C-100uF",
                description: "100uF Electrolytic Capacitor - 16V",
                ERPUnit: "Pack",
                unitCost: 0.15,
                warehouse: "Atlanta",
                quantity: 2000,
                dimensions: { width: "0.5", thickness: "0.2", length: "1.0" } // Dimensions in inches
            },
            {
                stockCode: "C-220uF",
                description: "220uF Electrolytic Capacitor - 25V",
                ERPUnit: "Pack",
                unitCost: 0.20,
                warehouse: "New York",
                quantity: 1500,
                dimensions: { width: "0.5", thickness: "0.25", length: "1.2" } // Dimensions in inches
            }
        ]
    },
    {
        material: "Transistor",
        type: "NPN",
        industry: "Electronic Components",
        items: [
            {
                stockCode: "T-2N2222",
                description: "2N2222 NPN Transistor - General Purpose",
                ERPUnit: "Piece",
                unitCost: 0.50,
                warehouse: "Austin",
                quantity: 1000,
                dimensions: { width: "0.2", thickness: "0.1", length: "0.3" } // Dimensions in inches
            },
            {
                stockCode: "T-BC547",
                description: "BC547 NPN Transistor - General Purpose",
                ERPUnit: "Piece",
                unitCost: 0.40,
                warehouse: "Miami",
                quantity: 1200,
                dimensions: { width: "0.2", thickness: "0.1", length: "0.3" } // Dimensions in inches
            }
        ]
    }
];

export default erpSearchData;
